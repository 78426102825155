import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import BaseModal from 'components/BaseModal'
import { useAppDispatch } from 'state/hooks'
import { setOpenModal } from 'state/app/reducer'
import { FlexDivCol } from 'components/layout/flex'
import { EXTERNAL_LINKS } from 'constants/links'

import NoteIcon from 'assets/svg/app/help-center.svg'
import DocsIcon from 'assets/svg/social/docs.svg'
import CommunityIcon from 'assets/svg/social/community.svg'
import ChatIcon from 'assets/svg/social/chat.svg'
import FeedbackIcon from 'assets/svg/app/feedback.svg'

const lists = [
	// {
	// 	icon: <NoteIcon />,
	// 	title: 'Help center',
	// 	description: 'Explore tutorials and help articles',
	// 	href: '',
	// },
	{
		icon: <DocsIcon />,
		title: 'Documentation',
		description: 'Explore the architecture that powers DexToro',
		href: EXTERNAL_LINKS.Docs.Documentation,
	},
	{
		icon: <CommunityIcon />,
		title: 'Join Discord',
		description: 'Chat with our community of traders',
		href: EXTERNAL_LINKS.Social.Discord,
	},
	{
		icon: <ChatIcon />,
		title: 'Live chat',
		description: 'Get live support and help',
		href: 'https://discord.com/invite/dextoro',
	},
	// {
	// 	icon: <FeedbackIcon />,
	// 	title: 'Provide feedback',
	// 	description: 'Product feedback and requests',
	// 	href: '',
	// },
]

const Help = () => {
	const dispatch = useAppDispatch()
	const onClose = () => dispatch(setOpenModal(null))

	return (
		<StyledBaseModal title="Help" isOpen onDismiss={onClose}>
			<Container>
				{lists.map((l, i) => (
					<Link href={l.href} key={i} target="_blank">
						<Icon>{l.icon}</Icon>
						<FlexDivCol rowGap="4px">
							<Title>{l.title}</Title>
							<Description>{l.description}</Description>
						</FlexDivCol>
					</Link>
				))}
			</Container>
		</StyledBaseModal>
	)
}

export default Help

const StyledBaseModal = styled(BaseModal)`
	padding-top: 120px;
	backdrop-filter: blur(5px);
	[data-reach-dialog-content] {
		width: 300px;
	}
`

const Container = styled.div`
	padding: 0;
	height: calc(100% - 68px);
	width: 100%;
	overflow-y: auto;
	background: ${(props) => props.theme.colors.selectedTheme.background};

	a {
		display: flex;
		align-items: center;
		height: 68px;
		gap: 16px;

		&:hover {
			div {
				color: ${(props) => props.theme.colors.selectedTheme.primary};
			}

			svg {
				path {
					fill: ${(props) => props.theme.colors.selectedTheme.primary};
				}
			}
		}
	}

	svg {
		width: 16px;
		height: 16px;
	}
`

const Icon = styled.div`
	display: flex;
	align-items: center;
`

const Title = styled.div`
	font-size: 15px;
	line-height: 20px;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primaryWhite};
`

const Description = styled.div`
	font-size: 13px;
	line-height: 16px;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.secondary};
`
