import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { FlexDivCol } from 'components/layout/flex'
import { EXTERNAL_LINKS } from 'constants/links'

import NoteIcon from 'assets/svg/app/help-center.svg'
import DocsIcon from 'assets/svg/social/docs.svg'
import CommunityIcon from 'assets/svg/social/community.svg'
import ChatIcon from 'assets/svg/social/chat.svg'
import FeedbackIcon from 'assets/svg/app/feedback.svg'

const lists = [
	// {
	// 	icon: <NoteIcon />,
	// 	title: 'Help center',
	// 	description: 'Explore tutorials and help articles',
	// 	href: '',
	// },
	{
		icon: <DocsIcon />,
		title: 'Documentation',
		description: 'Explore the architecture that powers DexToro',
		href: EXTERNAL_LINKS.Docs.Documentation,
	},
	{
		icon: <CommunityIcon />,
		title: 'Join Discord',
		description: 'Chat with our community of traders',
		href: EXTERNAL_LINKS.Social.Discord,
	},
	{
		icon: <ChatIcon />,
		title: 'Live chat',
		description: 'Get live support and help',
		href: 'https://discord.com/invite/dextoro',
	},
	// {
	// 	icon: <FeedbackIcon />,
	// 	title: 'Provide feedback',
	// 	description: 'Product feedback and requests',
	// 	href: '',
	// },
]

const Help = () => {
	return (
		<Container>
			{lists.map((l, i) => (
				<Link href={l.href} key={i}>
					<Icon>{l.icon}</Icon>
					<FlexDivCol rowGap="4px">
						<Title>{l.title}</Title>
						<Description>{l.description}</Description>
					</FlexDivCol>
				</Link>
			))}
		</Container>
	)
}

export default Help

const Container = styled.div`
	padding: 0;
	position: fixed;
	top: 0;
	left: 0;
	height: calc(100% - 68px);
	width: 100%;
	overflow-y: auto;
	z-index: 9999;
	background: ${(props) => props.theme.colors.selectedTheme.background};
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};

	a {
		display: flex;
		align-items: center;
		height: 92px;
		padding: 0 28px;
		gap: 16px;
		border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
	}

	svg {
		width: 20px;
		height: 20px;
	}
`

const Icon = styled.div`
	display: flex;
	align-items: center;
`

const Title = styled.div`
	font-size: 18px;
	line-height: 22px;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primaryWhite};
`

const Description = styled.div`
	font-size: 15px;
	line-height: 20px;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.secondary};
`
