import { truncateAddress } from '@dextoroprotocol/sdk/utils'
import { useAccountModal, useConnectModal } from '@rainbow-me/rainbowkit'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useEnsAvatar, useEnsName } from 'wagmi'

import { useSelector } from 'react-redux'
import Button from 'components/Button'
import Connector from 'containers/Connector'

import { selectShowModal, showDefaultTab } from 'state/app/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setDefaultTab, setOpenModal } from 'state/app/reducer'
import { selectSmartMarginAccount } from 'state/futures/smartMargin/selectors'
import useLocalStorage from 'hooks/useLocalStorage'
import FirstSeenWalletConnectionModal from 'sections/shared/modals/FirstSeenWalletConnectionModal'

import NetworksSwitcher from '../NetworksSwitcher'
import OneClickTrading from '../OneClickTrading'

export const MobileWalletActions: FC = () => {
	const dispatch = useAppDispatch()
	const { walletAddress } = Connector.useContainer()
	const { isWalletConnected } = Connector.useContainer()
	const { data: ensName } = useEnsName({ address: walletAddress!, chainId: 1 })
	const { openConnectModal } = useConnectModal()
	const { openAccountModal } = useAccountModal()
	const smartMarginAccount = useAppSelector(selectSmartMarginAccount)
	const defaultTab = useSelector(showDefaultTab)
	const openModal = useAppSelector(selectShowModal)

	const [walletLabel, setWalletLabel] = useState<string>('')
	const truncatedWalletAddress = truncateAddress(walletAddress! ?? '')

	const openDepositModal = () => {
		dispatch(setOpenModal('futures_smart_margin_manage'))
		dispatch(setDefaultTab('deposit'))
	}

	const [seenFlag, setSeenFlag] = useLocalStorage<boolean>('SEEN_FALG', false)
	const [firstVisitModal, setFirstVisitModal] = useState<boolean>(false)

	const openConnect = () => {
		if (seenFlag) {
			openConnectModal?.()
		} else {
			setFirstVisitModal(true)
		}
	}

	const closeFirstVisitModal = () => {
		setFirstVisitModal(false)
	}

	const visitModalConfirmed = () => {
		setSeenFlag(true)
		openConnectModal?.()
		setFirstVisitModal(false)
	}

	useEffect(() => {
		setWalletLabel(ensName || truncatedWalletAddress!)
	}, [ensName, truncatedWalletAddress])

	return (
		<StyledButton variant="primary" onClick={isWalletConnected ? openDepositModal : openConnect}>
			Deposit
		</StyledButton>
	)
}

const StyledButton = styled(Button)`
	width: 100%;
	height: 44px;
	min-height: 44px;
	font-size: 17px;
	line-height: 20px;
	padding: 0px 16px;
	line-height: normal;
`

export default MobileWalletActions
