import React from 'react'
import styled from 'styled-components'
import { useAppDispatch } from 'state/hooks'
import { setShowWalkthrough } from 'state/app/reducer'
import Icon from 'assets/svg/app/get-started.svg'

const GetStarted = () => {
	const dispatch = useAppDispatch()

	return (
		<Container>
			<FooterLink onClick={() => dispatch(setShowWalkthrough('show'))}>
				<Icon width={16} height={16} />
				Get Started
			</FooterLink>
		</Container>
	)
}

export default GetStarted

const Container = styled.div`
	display: flex;
	cursor: pointer;
	height: 29px;
`

const FooterLink = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 5px;
	padding: 4px 12px;
	margin-left: 8px;
	column-gap: 5px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	font-size: 13px;
	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		color: ${(props) => props.theme.colors.selectedTheme.icon.hover};
		svg {
			path {
				stroke: ${(props) => props.theme.colors.selectedTheme.icon.hover};
			}
		}
	}
`
