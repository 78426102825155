import { FC, useRef } from 'react'
import styled from 'styled-components'

import { FlexDivCol } from 'components/layout/flex'
import { MobileHiddenView } from 'components/Media'

import Banner from '../../HomeLayout/Banner'
import Logo from '../../Logo'
import Connector from 'containers/Connector'

import Nav from './Nav'
import WalletButtons from './WalletButtons'
import CloseIconWithHover from 'sections/shared/components/CloseIconWithHover'
import Link from 'next/link'
type LogoNavProps = {
	hasWalletAddress?: boolean
}
const Header: FC = () => {
	const { walletAddress } = Connector.useContainer()
	const showRestrictedBanner = sessionStorage.getItem('complianceBannerShown')
	const showRestrictedWarnig = useRef(true)
	const handleClose = () => {
		sessionStorage.removeItem('complianceBannerShown')
		showRestrictedWarnig.current = false
	}

	return (
		<MobileHiddenView>
			<FlexDivCol>
				<Container>
					<LogoNav hasWalletAddress={!!walletAddress}>
						<BorderRightDiv>
							<Logo />
						</BorderRightDiv>
						<Nav />
					</LogoNav>
					<WalletButtons />
				</Container>
				<Banner />
				{showRestrictedBanner && showRestrictedWarnig.current && (
					<ResrtictedWarningBanner>
						<div style={{ flex: '1' }}>
							Perpetuals are not available to any persons who are residents of, are located or
							incorporated in, or have a registered agent in a blocked country or a restricted
							territory. More details can be found in our{' '}
							<Link href="/terms" target="_blank">
								Terms of Use
							</Link>
							. If you think this is an error, please contact support in the{' '}
							<Link href="https://discord.gg/dextoro" target="_blank">
								Help center
							</Link>
							.
						</div>
						<CloseIconWithHover onClick={handleClose} />
					</ResrtictedWarningBanner>
				)}
			</FlexDivCol>
		</MobileHiddenView>
	)
}

const Container = styled.header`
	display: flex;
	justify-content: space-between;
	padding: 5px;
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
	max-height: 48px;
`
const BorderRightDiv = styled.div`
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
`

const LogoNav = styled.div<LogoNavProps>`
	display: flex;
	align-items: center;
	max-width: ${(props) => (props.hasWalletAddress ? 'calc(100% - 320px)' : 'calc(100% - 155px)')};
	nav {
		max-width: calc(100% - 45px);
	}
`
// max-width: calc(100% - 155px);

const ResrtictedWarningBanner = styled.div`
	display: flex;
	align-items: center;
	background-color: #d35f5b;
	color: #f7f7f7;
	text-align: left;
	padding: 10px 20px;
	font-size: 13px;
	font-weight: 500;

	a {
		color: white;
		font-weight: bold;
		text-decoration: underline;
	}
	svg {
		margin-left: 10px;
		width: 11px;
		height: 13px;
		padding-top: 2px;
		stroke: #f7f7f7;
		cursor: pointer;
	}
`

export default Header
