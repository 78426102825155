import { memo, ChangeEvent, FC, useCallback, useRef } from 'react'
import styled from 'styled-components'

import CrossIcon from 'assets/svg/app/close.svg'
import Input from 'components/Input/Input'
import media from 'styles/media'

type SearchProps = {
	value: string | undefined
	disabled?: boolean
	border?: boolean
	placeHolder?: string
	autoFocus?: boolean
	onChange: (text: string) => void
	onClear?: () => void
}

const SearchBarNew: FC<SearchProps> = memo(
	({ value, disabled, border = true, placeHolder, autoFocus, onChange, onClear }) => {
		const inputRef = useRef<HTMLInputElement>(null!)

		const handleOnChange = useCallback(
			(event: ChangeEvent<HTMLInputElement>) => {
				onChange(event.target.value)
			},
			[onChange]
		)

		const onKeyClear = useCallback(
			(event: React.KeyboardEvent<HTMLInputElement>) => {
				if (event.key === 'Escape') {
					onClear?.()
				}
			},
			[onClear]
		)

		return (
			<SearchBar border={border}>
				<IconContainer
					onClick={() => {
						onClear?.()
						inputRef.current?.focus()
					}}
				>
					<StyledCrossIcon width={32} height={32} />
				</IconContainer>
				<SearchInput
					autoFocus={autoFocus}
					border={border}
					value={value}
					onChange={handleOnChange}
					placeholder={placeHolder}
					disabled={disabled}
					onKeyDown={onKeyClear}
					ref={inputRef}
				/>
			</SearchBar>
		)
	}
)

const IconContainer = styled.div`
	position: absolute;
	left: 2;
	z-index: 1;
	border-radius: 16px;
	padding-top: 5px;
	cursor: pointer;
`

const StyledCrossIcon = styled(CrossIcon)`
	border-radius: 50%;
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	padding: 8px;
	&:hover {
		path {
			stroke: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
		}
	}
`

const SearchInput = styled(Input)<{ border: boolean }>`
	position: relative;
	height: 40px;
	width: 100%;
	border: 0;
	border-radius: 50px;
	padding: 10px 0px 10px 40px;
	font-size: 14px;
	background: ${(props) => props.theme.colors.selectedTheme.table.fill};

	${media.lessThan('sm')`
		font-size: 13px;
	`}

	::placeholder {
		color: ${(props) => props.theme.colors.selectedTheme.gray};
	}
`

const SearchBar = styled.div<{ border: boolean }>`
	justify-content: start;
	margin-left: 0;
	padding-left: 0;
	opacity: 1;
	width: var(${CrossIcon});
	border-radius: 16px;
	font-size: 14px;
	line-height: 18px;
	height: 32px;
	min-height: 32px;
	font-family: ${(props) => props.theme.fonts.mono};
	font-weight: 400;
	font-feature-settings: 'zero' 1;
	display: flex;
	flex: 1 1;
	overflow: hidden;
	align-items: center;
	background: ${(props) =>
		props.border
			? props.theme.colors.selectedTheme.input.background
			: props.theme.colors.selectedTheme.newTheme.containers.primary.background};
`

export default SearchBarNew
