import { FC, memo, useRef } from 'react'
import styled, { css } from 'styled-components'

import { DesktopOnlyView, MobileOrTabletView } from 'components/Media'
import Banner from 'sections/shared/Layout/HomeLayout/Banner'
import Footer from 'sections/shared/Layout/AppLayout/Footer'
import Header from 'sections/shared/Layout/AppLayout/Header'
import MobileUserMenu from 'sections/shared/Layout/AppLayout/Header/MobileUserMenu'

import NotificationContainer from 'constants/NotificationContainer'
import { MobileScreenContainer } from 'styles/common'
import { useOneClickTradingData } from 'state/oneClickTrading/hooks'
import CloseIconWithHover from 'sections/shared/components/CloseIconWithHover'
import { selectShowBanner } from 'state/app/selectors'
import { useAppSelector } from 'state/hooks'
import Link from 'next/link'

type AppLayoutProps = {
	children: React.ReactNode
}

const AppLayout: FC<AppLayoutProps> = memo(({ children }) => {
	const showBanner = useAppSelector(selectShowBanner)
	useOneClickTradingData()
	const showRestrictedBanner = sessionStorage.getItem('complianceBannerShown')
	const showRestrictedWarnig = useRef(true)
	const handleClose = () => {
		sessionStorage.removeItem('complianceBannerShown')
		showRestrictedWarnig.current = false
	}

	return (
		<AppLayoutContainer>
			<DesktopOnlyView>
				<DesktopGridContainer>
					<Header />
					<main>{children}</main>
					<Footer />
				</DesktopGridContainer>
			</DesktopOnlyView>
			<MobileOrTabletView>
				<MobileScreenContainer>
					<Banner />
					{showRestrictedBanner && showRestrictedWarnig.current && (
						<ResrtictedWarningBanner showBanner={showBanner ? true : false}>
							<div style={{ flex: '1' }}>
								Perpetuals are not available to any persons who are residents of, are located or
								incorporated in, or have a registered agent in a blocked country or a restricted
								territory. More details can be found in our{' '}
								<Link href="/terms" target="_blank">
									Terms of Use
								</Link>
								. If you think this is an error, please contact support in the{' '}
								<Link href="https://discord.gg/dextoro" target="_blank">
									Help center
								</Link>
								.
							</div>
							<CloseIconWithHover onClick={handleClose} />
						</ResrtictedWarningBanner>
					)}
					{children}
					<MobileUserMenu />
				</MobileScreenContainer>
			</MobileOrTabletView>
			<NotificationContainer />
		</AppLayoutContainer>
	)
})

const AppLayoutContainer = styled.div`
	height: 100%;

	> div {
		height: 100%;
	}
`

const DesktopGridContainer = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template: auto 1fr auto / 100%;

	> main {
		display: flex;
		min-height: 0;
		width: 100%;
		overflow-y: auto;

		> div {
			width: 100%;
			height: 100%;
		}
	}
`
const ResrtictedWarningBanner = styled.div<{ showBanner: boolean }>`
	${(props) =>
		!props.showBanner &&
		css`
			margin-top: 58px;
		`}
	display: flex;
	align-items: center;
	background-color: #d35f5b;
	color: #f7f7f7;
	text-align: left;
	font-weight: 500;

	font-size: 13px;
	padding: 8px 16px;

	a {
		color: white;
		font-weight: bold;
		text-decoration: underline;
	}
	svg {
		margin-left: 10px;
		width: 11px;
		height: 13px;
		padding-top: 2px;
		stroke: #f7f7f7;
		cursor: pointer;
		align-self: baseline;
	}
`

export default AppLayout
