import { createAsyncThunk } from '@reduxjs/toolkit'
import { wei } from '@synthetixio/wei'

import type { ThunkConfig } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import { selectSmartMarginAccount } from 'state/futures/smartMargin/selectors'

import {
	setDelegated,
	setSessionActivated,
	setSessionValidUntil,
	setSmartAccountAddress,
	setSmartAccountBalance,
	setUsingBiconomy,
	setWalletBalance,
} from './reducer'
import { selectDelegated, selectSmartAccountAddress } from './selectors'

export const getOneClickSmartAccount = createAsyncThunk<void, void, ThunkConfig>(
	'oneClick/getOneClickSmartAccount',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const wallet = selectWallet(getState())
		if (!wallet) return

		try {
			const smartAccount = await sdk.biconomy.initSmartAccount()
			const provider = sdk.biconomy.getProvider()

			const smartAccountAddress = await smartAccount.getAccountAddress()
			const smartAccountBalance = await provider.getBalance(smartAccountAddress)
			const walletBalance = await provider.getBalance(wallet)
			dispatch(setSmartAccountAddress(smartAccountAddress))
			dispatch(setSmartAccountBalance(wei(smartAccountBalance).toString()))
			dispatch(setWalletBalance(wei(walletBalance).toString()))
		} catch (error) {
			throw error
		}
	}
)

export const getDelegated = createAsyncThunk<void, void, ThunkConfig>(
	'oneClick/getDelegated',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const smartMarginAccount = selectSmartMarginAccount(getState())
		const smartAccountAddress = getState().oneClick.smartAccountAddress
		if (!smartAccountAddress || !smartMarginAccount) {
			dispatch(setDelegated(false))
			return
		}

		const delegated = await sdk.futures.delegates(smartMarginAccount, smartAccountAddress)
		dispatch(setDelegated(delegated))
	}
)

export const getSessionStatus = createAsyncThunk<void, void, ThunkConfig>(
	'oneClick/getSessionStatus',
	async (_, { getState, dispatch }) => {
		const delegated = selectDelegated(getState())
		const smartAccountAddress = selectSmartAccountAddress(getState())
		const now = Math.floor(Date.now() / 1000)
		const validUntil =
			window.localStorage.getItem(`dextoro-valid-until-${smartAccountAddress}`) ?? 0
		const isValidSession = Number(validUntil) > now

		dispatch(setSessionActivated(delegated && isValidSession))
		dispatch(setUsingBiconomy(isValidSession))
		dispatch(setSessionValidUntil(validUntil))
	}
)
