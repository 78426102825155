import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { wei } from '@synthetixio/wei'
import media from 'styles/media'

import LightOnIcon from 'assets/svg/app/light-on.svg'
import LightOffIcon from 'assets/svg/app/light-off.svg'
import Button from 'components/Button'
import ManageSession from 'sections/futures/ManageSmartMarginAccount/OneClickTrading/ManageSession'
import { DEFAULT_TX_FEE } from 'constants/defaults'

import useClickOutside from 'hooks/useClickOutside'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
	selectDelegated,
	selectSessionActivated,
	selectSmartAccountBalance,
} from 'state/oneClickTrading/selectors'
import { selectShowModal } from 'state/app/selectors'
import { setDefaultTab, setOpenModal } from 'state/app/reducer'

import OneClickTradingModal from './OneClickTradingModal'

const OneClickTrading: FC = () => {
	const { t } = useTranslation()
	const { ref } = useClickOutside(() => setOpenMenu(false))
	const dispatch = useAppDispatch()

	const delegated = useAppSelector(selectDelegated)
	const isSessionActivated = useAppSelector(selectSessionActivated)
	const smartAccountBalance = useAppSelector(selectSmartAccountBalance)
	const isLowBalance = wei(smartAccountBalance).lt(DEFAULT_TX_FEE)
	const showModal = useAppSelector(selectShowModal)
	const openOneClickModal = showModal === 'oneclick_active_delegate'

	const [openMenu, setOpenMenu] = useState(false)

	const LightIcon = isSessionActivated ? LightOnIcon : LightOffIcon

	const handleOpenModal = () => {
		dispatch(setOpenModal('oneclick_active_delegate'))
		setOpenMenu(false)
	}

	const handleCloseModal = () => {
		dispatch(setOpenModal(null))
	}

	const handleDeposit = () => {
		dispatch(setOpenModal('futures_smart_margin_manage'))
		dispatch(setDefaultTab('oneclick-trading'))
		setOpenMenu(false)
	}

	return (
		<div ref={ref}>
			<IconButton onClick={() => setOpenMenu(!openMenu)} noOutline>
				<LightIcon width={20} />
			</IconButton>
			{openMenu && (
				<Container>
					{delegated ? (
						<>
							<ManageSession isMenu />
							{isLowBalance && (
								<DepositButton onClick={handleDeposit}>
									{t('dashboard.oneclick.fund.deposit')}
								</DepositButton>
							)}
						</>
					) : (
						<ActivateButton onClick={handleOpenModal}>
							{t('dashboard.oneclick.manage.activate-button')}
						</ActivateButton>
					)}
				</Container>
			)}
			<OneClickTradingModal isOpenModal={openOneClickModal} onDismiss={handleCloseModal} />
		</div>
	)
}

const IconButton = styled(Button)`
	display: grid;
	place-items: center;
	height: 32px;
	width: 32px;
	padding: 0px;
	background: none;
	border: none;

	:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		svg {
			path {
				fill: ${(props) => props.theme.colors.selectedTheme.icon.hover};
			}
		}
	}
`

const Container = styled.div`
	z-index: 100;
	position: absolute;
	right: 5%;
	top: 1px;
	background: ${(props) => props.theme.colors.selectedTheme.table.fill};
	padding: 16px;
	border: ${(props) => props.theme.colors.selectedTheme.border};
	border-radius: 12px;

	${media.greaterThan('mdUp')`
		margin-top: 56px;
	`};

	${media.lessThan('md')`
	bottom: 0;
	right: 0;
	top: auto;
	width: 100%;
	min-height: 90px;
	border-radius: 12px 12px 0 0;
	`}
`

const ActivateButton = styled(Button)`
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	font-size: 14px;

	${media.lessThan('md')`
		width: 100%;
	`}
`

const DepositButton = styled(Button)`
	width: 100%;
	margin-top: 16px;
`

export default OneClickTrading
