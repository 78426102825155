import styled from 'styled-components'

export const MenuButton = styled.div<{ isActive?: boolean; isLink?: boolean }>`
	display: flex;
	align-items: center;
	align-self: flex-start;
	width: 100%;
	font-size: 26px;
	line-height: 32px;
	margin: 10px auto;
	cursor: pointer;
	outline: none;
	text-transform: capitalize;
	color: ${(props) =>
		props.isActive
			? props.theme.colors.selectedTheme.newTheme.text.primary
			: props.theme.colors.selectedTheme.gray};
`
