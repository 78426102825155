import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Link from 'next/link'
import { useAppSelector } from 'state/hooks'
import { selectShowWalkthrough } from 'state/app/selectors'
import GitHashID from './GitHashID'
import OperationStatus from './OperationStatus'
import GetStarted from './GetStarted'

const Footer = () => {
	const { t } = useTranslation()
	const showWalkthrough = useAppSelector(selectShowWalkthrough)

	return (
		<FooterContainer>
			<OperationStatus />
			{showWalkthrough === 'hide' ? <GetStarted /> : <GitHashID />}
			<RightContainer>
				<Link target="_blank" href="https://ethereum.org/en/" rel="noreferrer">
					<PowerByStyle>
						<span>{t('homepage.footer.powered-by')}</span>
						<img width={'35px'} height={'25px'} alt="logo-star" src="/images/starkware.svg" />
						<span className="text-[1rem] font-[500]">{t('homepage.footer.ethereum')}</span>
					</PowerByStyle>
				</Link>
			</RightContainer>
		</FooterContainer>
	)
}

const FooterContainer = styled.footer`
	display: grid;
	z-index: 120;
	grid-template: 36px/320px 1fr auto;
	align-items: center;
	padding-right: 10px;
	border-top: ${(props) => props.theme.colors.selectedTheme.border};
	background-color: ${(props) =>
		props.theme.colors.selectedTheme.newTheme.containers.primary.background};
`

const RightContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 0 16px;
	line-height: 8px;
	border-left: ${(props) => props.theme.colors.selectedTheme.border};
`

const PowerByStyle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	cursor: pointer;
	color: ${(props) => props.theme.colors.common.dark.gray};
`

export default Footer
