import { SynthSuspensionReason } from '@dextoroprotocol/sdk/types'
import React, { FC, memo, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import useIsMarketTransitioning from 'hooks/useIsMarketTransitioning'
import { marketIsOpen, marketNextOpen, marketNextTransition } from 'utils/marketHours'

import Badge from './Badge'
import { Body } from './Text'

type MarketBadgeProps = {
	currencyKey: string
	isFuturesMarketClosed?: boolean
	futuresClosureReason?: SynthSuspensionReason
	fallbackComponent?: ReactElement
}

type TransitionBadgeProps = {
	isOpen: boolean
}

const TransitionBadge: FC<TransitionBadgeProps> = memo(({ isOpen }) => {
	const { t } = useTranslation()

	return (
		// <StyledBadge color={isOpen ? 'yellow' : 'red'}>
		<NewBadge>{t(`futures.market.state.${isOpen ? 'closes' : 'opens'}-soon`)}</NewBadge>
		// </StyledBadge>
	)
})

export const MarketBadge: FC<MarketBadgeProps> = memo(
	({ currencyKey, isFuturesMarketClosed, futuresClosureReason, fallbackComponent }) => {
		const { t } = useTranslation()
		const isOpen = marketIsOpen(currencyKey)
		const nextOpen = marketNextOpen(currencyKey)
		const nextTransition = marketNextTransition(currencyKey)

		const timerSetting = isOpen === null ? null : isOpen ? nextTransition : nextOpen
		const isMarketTransitioning = useIsMarketTransitioning(timerSetting ?? null)

		if (typeof isFuturesMarketClosed !== 'boolean') {
			return null
		}

		if (isFuturesMarketClosed) {
			const reason = futuresClosureReason || 'unknown'
			return <NewBadge closureReason={reason}>{t(`futures.market.state.${reason}`)}</NewBadge>
		}

		if (isMarketTransitioning && isOpen !== null) {
			return <TransitionBadge isOpen={isOpen} />
		}

		return fallbackComponent ? fallbackComponent : null
	}
)

export default MarketBadge

const StyledBadge = styled(Badge)`
	padding: 1px 5px;
	line-height: 9px;
`

const NewBadge = styled(Body).attrs({ weight: 'bold', capitalized: true, type: 'span' })<{
	closureReason?: string
}>`
	padding: 3px 5px;
	border-radius: 4px;

	${(props) =>
		props.closureReason === 'market-closure'
			? css`
					color: ${props.theme.colors.selectedTheme.red};
					background: rgba(239, 104, 104, 0.1);
			  `
			: css`
					color: ${props.theme.colors.selectedTheme.text.value};
					background: ${props.theme.colors.selectedTheme.button.fillHover};
			  `};
	};
`

/* For Green color */
// color: ${props.theme.colors.selectedTheme.green};
// background: rgba(127, 212, 130, 0.1);
