import { FC } from 'react'
import media from 'styles/media'
import styled, { css } from 'styled-components'
import { PricesInfo } from 'state/prices/types'
import Wei from '@synthetixio/wei'
import { FuturesMarketAsset, SynthSuspensionReason } from '@dextoroprotocol/sdk/types'
import { MarketKeyByAsset, formatDollars, formatPercent } from '@dextoroprotocol/sdk/utils'
import { CurrencyName } from 'constants/currency'
import { selectMarketInfo } from 'state/futures/selectors'
import MarketBadge from 'components/MarketBadge'
import { Body, NumericValue } from 'components/Text'
import { FlexDivCentered } from 'components/layout/flex'
import CurrencyIcon from 'components/Currency/CurrencyIcon'
import DropdownArrow from 'assets/svg/app/dropdown-arrow.svg'

import { MARKETS_DETAILS_HEIGHT_DESKTOP, TRADE_PANEL_WIDTH_MD } from '../styles'
import Spacer from 'components/Spacer'
import { MaxLeverageButton } from './MarketsDropdown'
import { useAppSelector } from 'state/hooks'
import LeverageFiftyIcon from 'assets/svg/app/50.svg'
import Leverage25Icon from 'assets/svg/app/25.svg'
import { selectCurrentTheme } from 'state/preferences/selectors'

type Props = {
	asset: FuturesMarketAsset
	label: string
	description: string
	isMarketClosed?: boolean
	closureReason?: SynthSuspensionReason
	mobile?: boolean
	priceDetails: {
		oneDayChange: Wei
		priceInfo?: PricesInfo
	}
	onClick: () => void
	expanded: boolean
}

const MarketsDropdownSelector: FC<Props> = (props) => {
	const marketInfo = useAppSelector(selectMarketInfo)
	const maxLeverage = marketInfo?.appMaxLeverage.toNumber()
	const currentTheme = useAppSelector(selectCurrentTheme)
	return (
		<Container {...props}>
			<ContentContainer mobile={props.mobile} currentTheme={currentTheme}>
				<LeftContainer $mobile={props.mobile}>
					{props.expanded ? (
						<DropDownSelectWhite color="primary">Select market</DropDownSelectWhite>
					) : (
						<>
							{!props.mobile ? (
								<>
									<CurrencyIcon
										currencyKey={MarketKeyByAsset[props.asset]}
										width={31}
										height={31}
									/>
									<div className="currency-meta">
										<CurrencyLabel color="primaryWhite">
											<StyledLabelSpan> {props.label}</StyledLabelSpan>
											{maxLeverage && maxLeverage !== 50 && maxLeverage !== 25 ? (
												<MaxLeverageButton>{`${maxLeverage}×`}</MaxLeverageButton>
											) : null}

											{maxLeverage && (maxLeverage === 25 || maxLeverage === 50) ? (
												maxLeverage === 50 ? (
													<LeverageFiftyIcon className="leverage-icon" />
												) : (
													<Leverage25Icon className="leverage-icon" />
												)
											) : null}
											<MarketBadge
												currencyKey={props.asset}
												isFuturesMarketClosed={props.isMarketClosed}
												futuresClosureReason={props.closureReason}
											/>
										</CurrencyLabel>
									</div>
								</>
							) : (
								<>
									<CurrencyIcon
										currencyKey={MarketKeyByAsset[props.asset]}
										width={40}
										height={40}
									/>
									<div className="currency-meta">
										<Asset>{CurrencyName[MarketKeyByAsset[props.asset]]}</Asset>
										<AssetLabelWrap color="secondary" size="small" fontSize={13}>
											<AssetLabel>{props.label}</AssetLabel>
											<Spacer width={4} />
											{maxLeverage && maxLeverage !== 50 && maxLeverage !== 25 ? (
												<MaxLeverageButton>{`${maxLeverage}×`}</MaxLeverageButton>
											) : null}

											{maxLeverage && (maxLeverage === 25 || maxLeverage === 50) ? (
												maxLeverage === 50 ? (
													<LeverageFiftyIcon className="leverage-icon" />
												) : (
													<Leverage25Icon className="leverage-icon" />
												)
											) : null}
											<MarketBadge
												currencyKey={props.asset}
												isFuturesMarketClosed={props.isMarketClosed}
												futuresClosureReason={props.closureReason}
											/>
										</AssetLabelWrap>
									</div>
								</>
							)}
						</>
					)}

					{/* <DropDownSelect color="secondary">All markets</DropDownSelect> */}
					{props.mobile && !props.expanded && (
						<StyledDropdownArrow $flip={props.expanded} style={{ marginLeft: '20px' }} />
					)}
				</LeftContainer>
				{props.mobile && (
					<MobileRightContainer>
						<div>
							<NumericValueSize value={props.priceDetails.oneDayChange} colored>
								{formatDollars(props.priceDetails.priceInfo?.price ?? '0', {
									suggestDecimalsForAsset: props.asset,
								})}
							</NumericValueSize>
							<NumericValuePercent value={props.priceDetails.oneDayChange} colored>
								{formatPercent(props.priceDetails.oneDayChange)}
							</NumericValuePercent>
						</div>
					</MobileRightContainer>
				)}

				{!props.mobile && (
					<DropDownContainer>
						{props.expanded ? (
							<DropDownSelect color="secondary">Tap to close</DropDownSelect>
						) : (
							<DropDownSelect color="secondary">All markets</DropDownSelect>
						)}
						<StyledDropdownArrow $flip={props.expanded} />
					</DropDownContainer>
				)}
			</ContentContainer>
		</Container>
	)
}

export const MARKET_SELECTOR_HEIGHT_MOBILE = 58

export const CurrencyLabel = styled(Body)`
	font-size: 16px;
	display: flex;
	align-items: center;
	gap: 5px;
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
	padding-top: 1px;
`

const DropDownContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 1px;
	svg {
		overflow: visible;
	}
`

const DropDownSelect = styled(Body)`
	font-size: 14px;
	padding-left: 24px;
	min-width: 110px;
	color: #6f6e84;
`
const DropDownSelectWhite = styled(Body)`
	font-size: 16px;
	padding-right: 5px;
	padding-left: 5px;
	min-width: 130px;
`

const Container = styled.div`
	width: 100%;
	height: 100%;
`
const NumericValueSize = styled(NumericValue)`
	font-size: 20px;
	line-height: 24px;
`
const NumericValuePercent = styled(NumericValue)`
	font-size: 16px;
	line-height: 20px;
	margin-top: 2px;
`

export const ContentContainer = styled(FlexDivCentered)<{
	mobile?: boolean
	currentTheme: 'dark' | 'light'
}>`
	.currency-meta {
		${({ mobile }) =>
			!mobile
				? `
			display: flex;
			align-items: center;
			justify-content: start;
			flex-direction: row;
			margin-left: 2px;

    `
				: `
			flex: 1;
			flex-direction: column;
			margin-left: 2px;
    `}
		.leverage-icon {
			overflow: visible;
			width: 26px;
			// height: 11px;
			rect {
				stroke: ${(props) =>
					props.currentTheme === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.1)'};
			}
			path {
				fill: ${(props) => props.theme.colors.selectedTheme.text.value};
				stroke-width: 0.3;
				stroke: ${(props) => props.theme.colors.selectedTheme.text.value};
			}
		}
	}
	width: ${(props) => (props.mobile ? '100%' : TRADE_PANEL_WIDTH_MD - 1 + 'px')};

	${media.lessThan('md')`
		width: 100%;
	`}

	${(props) =>
		props.mobile &&
		css`
			width: 100%;
		`}

	background: ${(props) => props.theme.colors.selectedTheme.newTheme.containers.primary.background};

	color: ${(props) => props.theme.colors.selectedTheme.text.value};
	padding-left: 15px;
	padding-right: 15px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	&:hover {
		background: ${(props) =>
			props.theme.colors.selectedTheme.newTheme.button.cell.hover.background};
	}

	p {
		margin: 0;
	}

	&:not(:last-of-type) {
		margin-bottom: 4px;
	}

	height: ${(props) =>
		props.mobile ? MARKET_SELECTOR_HEIGHT_MOBILE : MARKETS_DETAILS_HEIGHT_DESKTOP - 1}px;
`

const Asset = styled.span`
	font-size: 20px;
	line-height: 24px;
	${(props) => props.theme.colors.selectedTheme.primary}
`

const LeftContainer = styled.div<{ $mobile?: boolean }>`
	flex: 1;
	display: flex;
	align-items: center;

	${(props) =>
		props.$mobile &&
		css`
			flex: none;
			padding-right: 20px;
			border-right: ${props.theme.colors.selectedTheme.border};
		`}
`

const MobileRightContainer = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-left: 15px;
	text-align: right;
`

const StyledDropdownArrow = styled(DropdownArrow)<{ mobile?: boolean }>`
	path {
		fill: ${(props) =>
			props.isActive
				? props.theme.colors.selectedTheme.button.text.primary
				: props.theme.colors.selectedTheme.gray};
	}
	transform: ${({ $flip }) => ($flip ? 'rotate(180deg)' : 'none')};
	transition: all 0.15s ease-in-out 0s !important;
	${(props) =>
		props.mobile &&
		css`
			margin-left: 10px;
		`}
`
const AssetLabelWrap = styled(Body)`
	display: flex;
	align-items: center;
`
const AssetLabel = styled.span`
	padding-top: 1px;
`

const StyledLabelSpan = styled.span`
	line-height: normal;
`

export default MarketsDropdownSelector
