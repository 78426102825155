import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import BaseModal from 'components/BaseModal'
import { BaseModalProps } from 'components/BaseModal'
import LiquidImage from 'assets/svg/platform-features/liquid.svg'
import AdvancedImage from 'assets/svg/platform-features/advanced.svg'
import RewardsImage from 'assets/svg/platform-features/rewards.svg'
import Button from 'components/Button'
import media from 'styles/media'
import { useAppSelector } from 'state/hooks'
import { selectCurrentTheme } from 'state/preferences/selectors'

type Props = {
	onDismiss(): void
	onConfirmed(): void
}
const FirstSeenWalletConnectionModal: React.FC<Props> = ({ onDismiss, onConfirmed }) => {
	const { t } = useTranslation()
	const currentTheme = useAppSelector(selectCurrentTheme)
	return (
		<StyledBaseModal
			title={t('common.first-seen.title')}
			onDismiss={onDismiss}
			$center={true}
			showCross={false}
			hasBackdropFilter={true}
			isCentered={true}
		>
			<Container>
				<Description>{t('common.first-seen.description')}</Description>
				<FunctionalityList>
					<FunctionalityWrapper currentTheme={currentTheme}>
						<FunctionalityIconWrapper>
							<LiquidImage />
						</FunctionalityIconWrapper>
						<FunctionalityContentWrapper>
							{t('common.first-seen.liquid_title')}
							<FunctionalityContentDescription>
								{t('common.first-seen.liquid_content')}
							</FunctionalityContentDescription>
						</FunctionalityContentWrapper>
					</FunctionalityWrapper>
					<FunctionalityWrapper currentTheme={currentTheme}>
						<FunctionalityIconWrapper>
							<AdvancedImage />
						</FunctionalityIconWrapper>
						<FunctionalityContentWrapper>
							{t('common.first-seen.advanced_title')}
							<FunctionalityContentDescription>
								{t('common.first-seen.advanced_content')}
							</FunctionalityContentDescription>
						</FunctionalityContentWrapper>
					</FunctionalityWrapper>
					<FunctionalityWrapper currentTheme={currentTheme}>
						<FunctionalityIconWrapper>
							<RewardsImage />
						</FunctionalityIconWrapper>
						<FunctionalityContentWrapper>
							{t('common.first-seen.rewards_title')}
							<FunctionalityContentDescription>
								{t('common.first-seen.reward_content')}
							</FunctionalityContentDescription>
						</FunctionalityContentWrapper>
					</FunctionalityWrapper>
				</FunctionalityList>
				<Footer>
					<StyledButton active size="small" onClick={onConfirmed} currentTheme={currentTheme}>
						{t('common.first-seen.get_started_button')}
					</StyledButton>
					{/* <FooterDescriptionWrapper>
						{t('common.first-seen.footer_description')}
						<StyledAnchor href="https://dextoro.foundation">
							{t('common.first-seen.footer_foundation')}
						</StyledAnchor>
					</FooterDescriptionWrapper> */}
				</Footer>
			</Container>
		</StyledBaseModal>
	)
}
const Container = styled.div`
	color: ${(props) => props.theme.colors.common.primaryWhite};
`

const Description = styled.div`
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
	font-size: 14px;
`

const FunctionalityList = styled.div`
	display: flex;
	flex-direction: column;
	margin: 24px 0;
`
const FunctionalityWrapper = styled.div<{ currentTheme: string }>`
	display: grid;
	grid-template-columns: 44px 1fr;
	gap: 16px;
	margin-bottom: 20px;
	&:hover {
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.1)' : 'brightness(0.9)')};
	}
	align-items: center;
`
const FunctionalityIconWrapper = styled.div`
	background-color: #454258;
	border-radius: 50%;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	height: 44px;
	width: 44px;
	margin-top: 2px;
`
const FunctionalityContentWrapper = styled.div`
	font-size: 16px;
	line-height: 20px;
	display: grid;
	grid-template-rows: auto 1fr;
	gap: 6px;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primaryWhite};
`
const FunctionalityContentDescription = styled.span`
	font-size: 14px;
	line-height: 18px;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.secondary};
`
const Footer = styled.div`
	display: flex;
	flex-direction: column;
`
const StyledButton = styled(Button)<{ currentTheme: string }>`
	color: ${(props) =>
		props.active
			? props.theme.colors.selectedTheme.button.dextoroPrimary.color
			: props.theme.colors.selectedTheme.button.text.lightWhite};
	background: ${(props) =>
		props.active
			? props.theme.colors.selectedTheme.button.dextoroPrimary.active
			: props.theme.colors.selectedTheme.button.fillHover};
	&:hover {
		background: ${(props) =>
			props.active
				? props.theme.colors.selectedTheme.button.dextoroPrimary.active
				: props.theme.colors.selectedTheme.button.fillHover};
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.1)' : 'brightness(0.9)')};
	}
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none;
	font-size: 14px;
	${media.lessThan('sm')`
		font-size: 12px;
		padding: 0 8px;
	`};
`
const FooterDescriptionWrapper = styled.div`
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	margin-top: 16px;
`
const StyledAnchor = styled.a`
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
	&:hover {
		color: ${(props) => props.theme.colors.common.purple};
		text-decoration: underline;
	}
`
interface StyledBaseModalProps extends BaseModalProps {
	$center: boolean
}

const StyledBaseModal = styled(BaseModal)<StyledBaseModalProps>`
	[data-reach-dialog-content] {
		width: 328px;
		cursor: default;
		display: flex;
		align-items: center;
	}
	${media.lessThan('sm')`
		[data-reach-dialog-content] {
            width: calc(100% - 16px) !important;
        }
	`}

	.card-header {
		padding: 24px 28px 8px;
		border: 0px;
		margin-bottom: 0;
		height: auto;
	}

	.card-body {
		padding: 0px 28px 24px;
	}
`

export default FirstSeenWalletConnectionModal
