import { FC, memo } from 'react'
import Link from 'next/link'

import { Body } from 'components/Text'

const GitHashID: FC = memo(() => {
	return (
		<Link href="https://github.com/dextoro" target="_blank" rel="noreferrer">
			<Body color="secondary" style={{ textAlign: 'center' }}></Body>
		</Link>
	)
})

export default GitHashID
