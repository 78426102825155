import { OperationalStatus } from '@dextoroprotocol/sdk/types'
import { formatDistance } from 'date-fns'
import { useMemo } from 'react'
import styled from 'styled-components'

import ChatIcon from 'assets/svg/social/chat.svg'
import OptionalIcon from 'assets/svg/app/link-new.svg'
import Tooltip from 'components/Tooltip/Tooltip'

import { useAppSelector } from 'state/hooks'
import common from 'styles/theme/colors/common'
import { MobileHiddenView, MobileOnlyView } from 'components/Media'

const OperationStatusThemeMap = {
	[OperationalStatus.FullyOperational]: {
		outer: common.palette.alpha.green20,
		inner: common.palette.green.g500,
	},
	[OperationalStatus.Degraded]: {
		outer: common.palette.alpha.red10,
		inner: common.palette.yellow.y500,
	},
	[OperationalStatus.Offline]: {
		outer: common.palette.alpha.red15,
		inner: common.palette.red.r300,
	},
} as const

const OperationStatus = () => {
	const dextoroStatus = useAppSelector(({ app }) => app.dextoroStatus)

	const content = useMemo(
		() => (
			<>
				<MobileHiddenView>
					<OperationStatusContainer>
						<FooterLink href="https://dextoro.instatus.com/">
							{/* <OuterCircle $status={dextoroStatus.status}>
								<InnerCircle $status={dextoroStatus.status} />
							</OuterCircle> */}
							<OuterCircle viewBox="0 0 30 30" $status={dextoroStatus.status}>
								<circle cx="15" cy="15" r="15" />
								<InnerCircle cx="15" cy="15" r="7.5" $status={dextoroStatus.status} />
							</OuterCircle>
							{'Operational'}
							<OptionalIcon width={16} height={16} />
						</FooterLink>
						<FooterLink href="https://discord.com/invite/dextoro">
							<ChatIcon width={16} height={16} />
							{'Help & Support'}
						</FooterLink>
					</OperationStatusContainer>
				</MobileHiddenView>
				<MobileOnlyView>
					<OperationalContainer>
						<FooterMobileLink href="https://dextoro.instatus.com/">
							<StatusCircle $status={dextoroStatus.status} />
							Operational
						</FooterMobileLink>
					</OperationalContainer>
				</MobileOnlyView>
			</>
		),
		[dextoroStatus.status]
	)

	const parsedDate = useMemo(() => {
		return dextoroStatus.lastUpdatedAt
			? ` (Last updated: ${formatDistance(
					new Date(dextoroStatus.lastUpdatedAt * 1000),
					new Date(),
					{
						addSuffix: true,
					}
			  )})`
			: ''
	}, [dextoroStatus.lastUpdatedAt])

	return !!dextoroStatus.message ? (
		<StyledTooltip height="auto" width="auto" content={`${dextoroStatus.message}${parsedDate}`}>
			{content}
		</StyledTooltip>
	) : (
		content
	)
}

const StyledTooltip = styled(Tooltip)`
	top: -50px;
	left: 0;
`

const OperationStatusContainer = styled.div`
	display: flex;
	cursor: default;
	height: 29px;
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
	height: 25px;
	justify-content: space-evenly;
`
const FooterLink = styled.a.attrs({ target: '_blank', rel: '_noreferrer' })`
	&:not(:last-of-type) {
		margin-right: 18px;
	}
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 5px;
	padding: 4px 12px;
	margin-right: 2px;
	column-gap: 5px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	font-size: 13px;
	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		color: ${(props) => props.theme.colors.selectedTheme.icon.hover};
		svg {
			path {
				stroke: ${(props) => props.theme.colors.selectedTheme.icon.hover};
			}
		}
	}
`
const OuterCircle = styled.svg<{ $status: OperationalStatus }>`
	display: block;
	margin-right: 5px;
	width: 15px;
	height: 15px;
	circle {
		fill: ${(props) => OperationStatusThemeMap[props.$status].outer};
	}
	overflow: visible;
`
// display: flex;
// justify-content: center;
// align-items: center;
// margin-right: 5px;
// width: 14px;
// height: 14px;
// border-radius: 50%;
// background: ${(props) => OperationStatusThemeMap[props.$status].outer};

const InnerCircle = styled.circle<{ $status: OperationalStatus }>`
	fill: ${(props) => OperationStatusThemeMap[props.$status].inner} !important;
`
// background-color: ${(props) => OperationStatusThemeMap[props.$status].inner};
// width: 7px;
// height: 7px;
// border-radius: 50%;

const OperationalContainer = styled.div`
	display: flex;
	align-items: center;
	font-size: 15px;
	line-height: 20px;
`

const FooterMobileLink = styled.a.attrs({ target: '_blank', rel: '_noreferrer' })`
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #6f6e84 !important;
`

const StatusCircle = styled.div<{ $status: OperationalStatus }>`
	height: 6px;
	width: 6px;
	border-radius: 50%;
	margin-right: 8px;
	background-color: ${(props) => OperationStatusThemeMap[props.$status].inner};
`

export default OperationStatus
