import React from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { formatPercent, getDisplayAsset } from '@dextoroprotocol/sdk/utils'
import {
	FuturesMarketAsset,
	FuturesMarketKey,
	SynthSuspensionReason,
} from '@dextoroprotocol/sdk/types'
import { Body } from 'components/Text'
import Spacer from 'components/Spacer'
import MarketBadge from 'components/MarketBadge'
import ChangePercent from 'components/ChangePercent'
import { FlexDiv, FlexDivRowCentered } from 'components/layout/flex'
import CurrencyIcon from 'components/Currency/CurrencyIcon'
import FavoriteIcon from 'assets/svg/futures/favorite-star.svg'
import { CurrencyName, CustomisedTickers } from 'constants/currency'
import Currency from 'components/Currency'
import { NO_VALUE } from 'constants/placeholder'
import { FlexLeverageDiv, MaxLeverageButton } from './MarketsDropdown'
import LeverageFiftyIcon from 'assets/svg/app/50.svg'
import Leverage25Icon from 'assets/svg/app/25.svg'
import { selectCurrentTheme } from 'state/preferences/selectors'
import { useAppSelector } from 'state/hooks'
import { wei } from '@synthetixio/wei'
import { ZERO_WEI } from '@dextoroprotocol/sdk/constants'

type MPDVProps = {
	data: {
		key: FuturesMarketKey
		value: FuturesMarketAsset
		label: string
		asset: FuturesMarketAsset
		description: string
		priceNum: number
		price: string
		change: number
		priceDirection: 'up' | 'down' | null
		isMarketClosed: boolean
		closureReason: SynthSuspensionReason
		openInterest: number
		fundingRate: number
		dailyVolume: number
		appMaxLeverage?: number
		isSuspended?: boolean
	}[]
	favMarkets: string[]
	onSelectFav: (asset: string) => void
	onSelectMarket: (asset: string) => void
	filter: boolean
	setFilter: React.Dispatch<React.SetStateAction<boolean>>
}

interface TextInSvgProps {
	text: any
}

const getWidth = (text: string): number => {
	const length = text.length
	if (text.includes('I') && length === 3) {
		return 25
	}
	switch (length) {
		case 3:
			return text.includes('I') ? 25 : 30
		case 4:
			// return text.includes('I') ? 35 : 35
			return 35
		case 5:
			return 40
		case 6:
			return 45
		default:
			return 25
	}
}

const TextInSvg: React.FC<TextInSvgProps> = ({ text }) => {
	const width = getWidth(text)
	return (
		<svg width={width} height="18" xmlns="http://www.w3.org/2000/svg" style={{ display: 'flex' }}>
			<rect width={width} height="18" rx="2" fill="#303044" />
			<text
				x="50%"
				y="50%"
				alignmentBaseline="central"
				dominantBaseline="central"
				textAnchor="middle"
				fill="#c3c2d4"
				fontSize="10"
				fontFamily="Relative-Medium-Pro"
				style={{ baselineShift: '-0.1' }}
				dx={'-0.2'}
			>
				{text}
			</text>
		</svg>
	)
}

const MarketPanelDetailedView: React.FC<MPDVProps> = ({
	data,
	favMarkets,
	onSelectFav,
	onSelectMarket,
	filter,
	setFilter,
}) => {
	const router = useRouter()
	const currentTheme = useAppSelector(selectCurrentTheme)
	return (
		<Container>
			{data.map((row) => (
				<Card
					onClick={() => {
						if (filter) {
							setFilter(false)
						} else {
							onSelectMarket(row.asset)
						}
					}}
					selected={router.asPath.includes(row.asset)}
				>
					<CoinInfo>
						<CurrencyContainer>
							<FavoriteIconWrapper
								onClick={(e) => {
									onSelectFav(row.asset)
									e.stopPropagation()
								}}
							>
								{favMarkets.includes(row.asset) ? (
									<StyledSelectedIcon height={14} width={15} />
								) : (
									<StyledFavoriteIcon height={14} width={15} />
								)}
							</FavoriteIconWrapper>
							<CurrencyIcon currencyKey={row.key} width={35} height={35} />
							<Body type="span" size="large" style={{ fontSize: '15px' }}>
								{CurrencyName[row.key] || getDisplayAsset(row.asset)}
							</Body>
							<Spacer width={4} />
							{/* <StyledButton>
								{getDisplayAsset(CustomisedTickers[row.key] || row.asset)}
							</StyledButton> */}
							<StyledAsset>
								{(() => {
									const rowValue = getDisplayAsset(CustomisedTickers[row.key] || row.asset)
									return <TextInSvg text={rowValue} />
								})()}
							</StyledAsset>
						</CurrencyContainer>
						<BadgeContainer>
							<Body type="span" size="large" style={{ fontSize: '15px', lineHeight: '20px' }}>
								{row.price}
							</Body>
							<FlexLeverageDiv currentTheme={currentTheme} maxLeverage={row?.appMaxLeverage}>
								<MarketBadge
									currencyKey={row.asset}
									isFuturesMarketClosed={row.isMarketClosed}
									futuresClosureReason={row.closureReason}
									fallbackComponent={
										<StyledChangePercent value={row.change} decimals={2} className="change-pct" />
									}
								/>
								<Spacer width={4} />
								{row?.appMaxLeverage && row.appMaxLeverage !== 50 && row.appMaxLeverage !== 25 ? (
									<MaxLeverageButton>{`${row.appMaxLeverage}×`}</MaxLeverageButton>
								) : null}

								{row?.appMaxLeverage && (row.appMaxLeverage === 25 || row.appMaxLeverage === 50) ? (
									row.appMaxLeverage === 50 ? (
										<LeverageFiftyIcon className="leverage-icon" />
									) : (
										<Leverage25Icon className="leverage-icon" />
									)
								) : null}
							</FlexLeverageDiv>
						</BadgeContainer>
					</CoinInfo>
					<CoinDetails>
						<VolumeBox>
							<DetailsKey>24h Volume</DetailsKey>
							<Currency.Price price={row.dailyVolume} fontSize={14} />
						</VolumeBox>
						<Divider />
						<OiBox>
							<DetailsKey>Open Interest</DetailsKey>
							<div>
								<Currency.Price
									price={row.openInterest.toFixed(2)}
									fontSize={14}
									formatOptions={{}}
								/>
								<Ticker>USD</Ticker>
							</div>
						</OiBox>
						<Divider />
						<FrBox>
							<DetailsKey>Funding Rate</DetailsKey>
							{/* <DetailsValue>
								{row.fundingRate
									? formatPercent(row.fundingRate ?? 0, { suggestDecimals: true, maxDecimals: 6 })
									: NO_VALUE}
							</DetailsValue> */}
							<Body
								as="span"
								weight="regular"
								size={'medium'}
								fontSize={14}
								className={`value ${
									wei(row.fundingRate)?.gt(ZERO_WEI)
										? 'green'
										: wei(row.fundingRate)?.lt(ZERO_WEI)
										? 'red'
										: undefined
								} ${row?.isSuspended ? 'paused' : ''}`}
							>
								{row.fundingRate
									? formatPercent(row.fundingRate ?? 0, { suggestDecimals: true, maxDecimals: 6 })
									: NO_VALUE}
							</Body>
						</FrBox>
					</CoinDetails>
				</Card>
			))}
		</Container>
	)
}

export default MarketPanelDetailedView

const Container = styled.div`
	height: 150vh;
	overflow: scroll;
	font-feature-settings: 'zero' 0;
`

const Card = styled.div<{ selected?: boolean }>`
	cursor: pointer;
	height: 104px;
	padding: 0 10px;
	background-color: ${(props) =>
		props.selected
			? props.theme.colors.selectedTheme.fill
			: props.theme.colors.selectedTheme.newTheme.containers.primary.background};
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
`

const CoinInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 54px;
	width: 100%;
`

const CoinDetails = styled.div`
	display: flex;
	justify-content: space-between;
	height: 48px;
	width: 100%;
	padding-left: 4px;
	padding-bottom: 16px;
`

const Divider = styled.span`
	width: 1px;
	height: 30px;
	margin: auto 0;
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
`

const VolumeBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
`

const OiBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
`

const FrBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: right;
	.value {
		color: ${(props) => props.theme.colors.selectedTheme.text.value};
	}

	.green {
		color: ${(props) => props.theme.colors.selectedTheme.green};
	}

	.red {
		color: ${(props) => props.theme.colors.selectedTheme.red};
	}

	.paused {
		color: ${(props) => props.theme.colors.selectedTheme.gray};
	}
`

const DetailsKey = styled.div`
	font-size: 13px;
	line-height: 16px;
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
`

const DetailsValue = styled.div`
	font-size: 14px;
	line-height: 18px;
	justify-content: center;
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
`

const Ticker = styled.span`
	display: inline-flex;
	margin: -2px 0 -2px 4px;
	padding: 0 3px 0 4px;
	font-size: 10px;
	font-weight: 500;
	line-height: 16px;
	border-radius: 2px;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	font-feature-settings: 'zero' 0;
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
`

const FavoriteIconWrapper = styled.div`
	cursor: 'pointer';
	margin: 0;
	zindex: 200;
	width: 22px !important;
	height: 22px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 11px;
	&:hover {
		background-color: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	}
`

const StyledSelectedIcon = styled(FavoriteIcon)`
	path {
		fill: #ffb648;
		stroke: #ffb648;
	}
`
const StyledFavoriteIcon = styled(FavoriteIcon)`
	path {
		stroke: ${(props) => props.theme.colors.selectedTheme.newTheme.text.secondary};
	}

	&:hover {
		path {
			stroke: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
		}
	}
`

const CurrencyContainer = styled(FlexDivRowCentered)`
	display: flex;
	flex-direction: row;
	text-align: left;
	align-items: left;
	width: 1000px;
	justify-content: flex-start;
`

const BadgeContainer = styled(FlexDivRowCentered)`
	display: flex;
	align-items: end;
	text-align: right;
	flex-direction: column;
	justify-content: flex-end;
	gap: 4px;
	width: 100%;
	padding: 4px 0;
	font-size: 13px;
`

const StyledChangePercent = styled(ChangePercent)`
	font-family: ${(props) => props.theme.fonts.regular};
`

const StyledButton = styled.div`
	font-family: ${(props) => props.theme.fonts.regular};
	font-weight: 500;
	font-size: 10px;
	line-height: 16px;
	display: inline-flex;
	padding: 1px 4px;
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
	border-radius: 2px;
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	text-transform: uppercase;
	letter-spacing: 0.06em;
`
const StyledAsset = styled.span`
	svg {
		rect {
			fill: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		}
		text {
			fill: ${(props) => props.theme.colors.selectedTheme.text.value};
		}
	}
`
