import { useAppSelector, useFetchAction } from 'state/hooks'
import { selectNetwork, selectWallet } from 'state/wallet/selectors'
import { selectSmartMarginAccount } from 'state/futures/smartMargin/selectors'

import { getDelegated, getOneClickSmartAccount, getSessionStatus } from './actions'
import { selectDelegated, selectSmartAccountAddress } from './selectors'

export const useOneClickTradingData = () => {
	const networkId = useAppSelector(selectNetwork)
	const wallet = useAppSelector(selectWallet)
	const smartMarginAccount = useAppSelector(selectSmartMarginAccount)
	const smartAccountAddress = useAppSelector(selectSmartAccountAddress)
	const delegated = useAppSelector(selectDelegated)

	useFetchAction(getOneClickSmartAccount, {
		dependencies: [networkId, wallet, smartMarginAccount, smartAccountAddress],
		disabled: !wallet,
	})

	useFetchAction(getDelegated, {
		dependencies: [networkId, wallet, smartMarginAccount, smartAccountAddress],
		disabled: !wallet,
	})

	useFetchAction(getSessionStatus, {
		dependencies: [networkId, wallet, smartMarginAccount, smartAccountAddress, delegated],
		disabled: !wallet,
	})
}
