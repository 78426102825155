import { NetworkId } from '@dextoroprotocol/sdk/types'
import { useChainModal, useConnectModal } from '@rainbow-me/rainbowkit'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Button from 'components/Button'
import MobileWalletActions from 'sections/shared/Layout/AppLayout/Header/MobileUserMenu/MobileWalletActions'

import Connector from 'containers/Connector'
import { isSupportedNetworkId } from 'utils/network'
import useLocalStorage from 'hooks/useLocalStorage'
import FirstSeenWalletConnectionModal from 'sections/shared/modals/FirstSeenWalletConnectionModal'
import { MobileFooterRight } from 'styles/common'

type MobileConnectButtonProps = {
	toggleModal(): void
}

const MobileConnectButton: React.FC<MobileConnectButtonProps> = ({ toggleModal }) => {
	const { t } = useTranslation()
	const { openConnectModal } = useConnectModal()
	const [seenFlag, setSeenFlag] = useLocalStorage<boolean>('SEEN_FALG', false)
	const [firstVisitModal, setFirstVisitModal] = useState<boolean>(false)

	const openConnect = () => {
		if (seenFlag) {
			openConnectModal?.()
		} else {
			setFirstVisitModal(true)
		}
	}

	const handleConnect = useCallback(() => {
		openConnect()
	}, [toggleModal, openConnectModal, seenFlag, openConnect])

	const closeFirstVisitModal = () => {
		setFirstVisitModal(false)
	}

	const visitModalConfirmed = () => {
		setSeenFlag(true)
		openConnectModal?.()
		setFirstVisitModal(false)
	}

	return (
		<>
			<ConnectButton
				size="small"
				variant="flat"
				noOutline
				onClick={handleConnect}
				data-testid="connect-wallet"
			>
				{t('common.wallet.connect-wallet')}
			</ConnectButton>
			{firstVisitModal && (
				<FirstSeenWalletConnectionModal
					onDismiss={closeFirstVisitModal}
					onConfirmed={visitModalConfirmed}
				></FirstSeenWalletConnectionModal>
			)}
		</>
	)
}

const MobileUnsupportedButton = () => {
	const { t } = useTranslation()
	const { openChainModal } = useChainModal()

	return (
		<ConnectButton
			size="small"
			variant="flat"
			data-testid="unsupported-network"
			mono
			onClick={openChainModal}
		>
			{t('common.wallet.unsupported-network')}
		</ConnectButton>
	)
}

type MobileWalletButtonProps = {
	toggleModal(): void
}

const MobileWalletButton: React.FC<MobileWalletButtonProps> = ({ toggleModal }) => {
	const { network, isWalletConnected } = Connector.useContainer()

	return (
		<>
			{!isWalletConnected ? (
				<MobileConnectButton toggleModal={toggleModal} />
			) : isSupportedNetworkId(network?.id as NetworkId) ? (
				<MobileWalletActions />
			) : (
				<MobileUnsupportedButton />
			)}
		</>
	)
}

const ConnectButton = styled(Button)`
	width: 100%;
	font-size: 17px;
`

export default MobileWalletButton
