import React, { useCallback, useMemo, useState } from 'react'
import { useAccountModal, useChainModal, useConnectModal } from '@rainbow-me/rainbowkit'
import { NetworkId } from '@dextoroprotocol/sdk/types'
import { truncateAddress } from '@dextoroprotocol/sdk/utils'
import { useTranslation } from 'react-i18next'
import { useEnsAvatar, useEnsName } from 'wagmi'
import styled from 'styled-components'

import Button from 'components/Button'
import Connector from 'containers/Connector'
import { isSupportedNetworkId } from 'utils/network'
import { FlexDivCentered } from 'components/layout/flex'
import DropdownArrow from 'assets/svg/app/dropdown-arrow.svg'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { selectSettings } from 'state/app/selectors'
import { enableSettings } from 'state/app/reducer'
import useLocalStorage from 'hooks/useLocalStorage'
import Settings from '../Settings'
import FirstSeenWalletConnectionModal from 'sections/shared/modals/FirstSeenWalletConnectionModal'
import { MobilePageTitle } from 'styles/common'

type MobileConnectButtonProps = {
	toggleModal(): void
}

const MobileConnectButton: React.FC<MobileConnectButtonProps> = ({ toggleModal }) => {
	const { t } = useTranslation()
	const { openConnectModal } = useConnectModal()

	const [seenFlag, setSeenFlag] = useLocalStorage<boolean>('SEEN_FALG', false)
	const [firstVisitModal, setFirstVisitModal] = useState<boolean>(false)

	const openConnect = () => {
		if (seenFlag) {
			openConnectModal?.()
		} else {
			setFirstVisitModal(true)
		}
	}

	const handleConnect = useCallback(() => {
		openConnect()
	}, [toggleModal, openConnectModal, seenFlag, openConnect])

	const closeFirstVisitModal = () => {
		setFirstVisitModal(false)
	}

	const visitModalConfirmed = () => {
		setSeenFlag(true)
		openConnectModal?.()
		setFirstVisitModal(false)
	}

	return (
		<>
			<ConnectButton
				size="small"
				variant="flat"
				noOutline
				onClick={handleConnect}
				data-testid="connect-wallet"
			>
				{t('common.wallet.connect-wallet')}
			</ConnectButton>
			{firstVisitModal && (
				<FirstSeenWalletConnectionModal
					onDismiss={closeFirstVisitModal}
					onConfirmed={visitModalConfirmed}
				></FirstSeenWalletConnectionModal>
			)}
		</>
	)
}

const Footer: React.FC = () => {
	const dispatch = useAppDispatch()
	const { walletAddress } = Connector.useContainer()
	const settings = useAppSelector(selectSettings)
	const { openAccountModal } = useAccountModal()
	const { data: ensAvatar } = useEnsAvatar({ address: walletAddress!, chainId: 1 })
	const { data: ensName } = useEnsName({ address: walletAddress!, chainId: 1 })

	const handleMenu = useCallback(() => {
		dispatch(enableSettings(!settings))
	}, [dispatch, settings])

	return (
		<FlexDivCentered>
			<WalletButton onClick={handleMenu} expanded={settings}>
				{ensAvatar ? (
					<img
						src={ensAvatar}
						alt={ensName?.toString()}
						width={16}
						height={16}
						style={{ borderRadius: '50%', marginRight: '8px' }}
					/>
				) : (
					<ConnectedIcon>
						<ConnectionIndicator />
					</ConnectedIcon>
				)}
				<StyledDropdownArrow expanded={settings} />
			</WalletButton>
			{settings && <Settings onDismiss={handleMenu} />}
		</FlexDivCentered>
	)
}

const MobileUnsupportedButton = () => {
	const { t } = useTranslation()
	const { openChainModal } = useChainModal()

	return (
		<ConnectButton
			size="small"
			variant="flat"
			data-testid="unsupported-network"
			mono
			onClick={openChainModal}
		>
			{t('common.wallet.unsupported-network')}
		</ConnectButton>
	)
}

type MobileFooterProps = {
	toggleModal(): void
}

const MobileFooter: React.FC<MobileFooterProps> = ({ toggleModal }) => {
	const { network, isWalletConnected } = Connector.useContainer()

	return (
		<>
			<MobilePageTitle>Menu</MobilePageTitle>
			{!isWalletConnected ? (
				<MobileConnectButton toggleModal={toggleModal} />
			) : isSupportedNetworkId(network?.id as NetworkId) ? (
				<Footer />
			) : (
				<MobileUnsupportedButton />
			)}
		</>
	)
}

const ConnectButton = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	color: ${(props) => props.theme.colors.selectedTheme.button.text.primaryWhite};
	cursor: pointer;
	border: none;
	width: auto;
	height: 36px;
	min-height: 36px;
	font-size: 16px;
	line-height: 20px;
	padding: 0px 12px;
	border-radius: 24px;
	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.fill};
	}
`

const WalletButton = styled(Button)<{ expanded?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none;
	height: 36px;
	min-height: 36px;
	font-size: 16px;
	line-height: 20px;
	padding: 0px 12px;
	border-radius: 24px;
	color: ${(props) => props.theme.colors.selectedTheme.primary};
	background: ${(props) =>
		props.expanded
			? props.theme.colors.selectedTheme.button.fill
			: props.theme.colors.selectedTheme.button.dextoroPrimary.background};
	font-family: ${(props) => props.theme.fonts.regular};
	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fill};
	}
`

const ConnectedIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 8px;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: rgba(127, 212, 130, 0.2);
`

const ConnectionIndicator = styled.div`
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: ${(props) => props.theme.colors.common.primaryGreen};
`

const StyledDropdownArrow = styled(DropdownArrow)<{ expanded?: boolean }>`
	margin-bottom: 2px;
	transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'rotate(360deg)')};
	transition: all 0.15s ease-in-out 0s !important;
`

export default MobileFooter
