import React from 'react'
import styled from 'styled-components'

type NavIconProps = { icon: any; onClick: () => void }

const NavIcon: React.FC<NavIconProps> = (props) => {
	return (
		<Container>
			<IconContainer>
				<IconButton onClick={props.onClick}>{props.icon}</IconButton>
			</IconContainer>
		</Container>
	)
}

export default NavIcon

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	height: auto;
`

const IconButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	cursor: pointer;
	transition: all 0.15s ease-in-out !important;

	&:hover {
		border-radius: 8px;
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		svg {
			path {
				fill: ${(props) => props.theme.colors.selectedTheme.icon.hover};
			}
			rect {
				stroke: ${(props) => props.theme.colors.selectedTheme.icon.hover};
			}
		}
	}
`
