import React from 'react'

export default function NotifyIcon() {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="bell-mask" fill="white">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8 15.5C9.10457 15.5 10 14.6046 10 13.5H6C6 14.6046 6.89543 15.5 8 15.5Z"
				></path>
			</mask>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 15.5C9.10457 15.5 10 14.6046 10 13.5H6C6 14.6046 6.89543 15.5 8 15.5Z"
				fill="#6f6e84"
			></path>
			<path
				d="M10 13.5H11V12.5H10V13.5ZM6 13.5V12.5H5V13.5H6ZM9 13.5C9 14.0523 8.55228 14.5 8 14.5V16.5C9.65685 16.5 11 15.1569 11 13.5H9ZM10 12.5H6V14.5H10V12.5ZM8 14.5C7.44772 14.5 7 14.0523 7 13.5H5C5 15.1569 6.34315 16.5 8 16.5V14.5Z"
				fill="#6f6e84"
				mask="url(#bell-mask)"
			></path>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.53082 2H7.46918C5.97623 2 4.71047 3.09779 4.49933 4.57573L4.02203 7.91687C3.97979 8.21252 3.87189 8.49495 3.70623 8.74343L1.86852 11.5H14.1315L12.2938 8.74343C12.1281 8.49495 12.0202 8.21252 11.978 7.91687L11.5007 4.57574C11.2895 3.09779 10.0238 2 8.53082 2ZM7.46918 1C5.47858 1 3.7909 2.46372 3.50939 4.43431L3.03208 7.77545C3.01096 7.92327 2.95701 8.06449 2.87418 8.18873L1.03647 10.9453C0.593431 11.6099 1.06982 12.5 1.86852 12.5H14.1315C14.9302 12.5 15.4066 11.6099 14.9635 10.9453L13.1258 8.18873C13.043 8.06449 12.989 7.92327 12.9679 7.77545L12.4906 4.43431C12.2091 2.46372 10.5214 1 8.53082 1H7.46918Z"
				fill="#6f6e84"
			></path>
		</svg>
	)
}

export function NotifyIconAlert() {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="bell-alert-mask" fill="white">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8 15.5C9.10457 15.5 10 14.6046 10 13.5H6C6 14.6046 6.89543 15.5 8 15.5Z"
				></path>
			</mask>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 15.5C9.10457 15.5 10 14.6046 10 13.5H6C6 14.6046 6.89543 15.5 8 15.5Z"
				fill="#6f6e84"
			></path>
			<path
				d="M10 13.5H11V12.5H10V13.5ZM6 13.5V12.5H5V13.5H6ZM9 13.5C9 14.0523 8.55228 14.5 8 14.5V16.5C9.65685 16.5 11 15.1569 11 13.5H9ZM10 12.5H6V14.5H10V12.5ZM8 14.5C7.44772 14.5 7 14.0523 7 13.5H5C5 15.1569 6.34315 16.5 8 16.5V14.5Z"
				fill="#6f6e84"
				mask="url(#bell-alert-mask)"
			></path>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.53526 1C8.53378 1 8.5323 1 8.53082 1H7.46918C5.47858 1 3.7909 2.46372 3.50939 4.43431L3.03208 7.77545C3.01096 7.92327 2.95701 8.06449 2.87418 8.18873L1.03647 10.9453C0.593431 11.6099 1.06982 12.5 1.86852 12.5H14.1315C14.9302 12.5 15.4066 11.6099 14.9635 10.9453L13.1258 8.18873C13.043 8.06449 12.989 7.92327 12.9679 7.77545L12.8444 6.91074C12.5722 6.96922 12.2898 7 12.0001 7C11.9487 7 11.8975 6.99903 11.8466 6.99711L11.978 7.91687C12.0202 8.21252 12.1281 8.49495 12.2938 8.74343L14.1315 11.5H1.86852L3.70623 8.74343C3.87189 8.49495 3.97979 8.21252 4.02203 7.91687L4.49933 4.57573C4.71047 3.09779 5.97623 2 7.46918 2H8.12615C8.21747 1.64523 8.35623 1.30951 8.53526 1ZM12.7025 5.91734L12.4906 4.43431C12.2631 2.84155 11.1169 1.57992 9.63463 1.15468C9.42379 1.42457 9.25814 1.73147 9.14904 2.064C10.3611 2.31853 11.3194 3.30708 11.5007 4.57574L11.702 5.98538C11.8001 5.99505 11.8995 6 12.0001 6C12.242 6 12.4772 5.97138 12.7025 5.91734Z"
				fill="#6f6e84"
			></path>
			<circle cx="12" cy="3" r="3" fill="#6966ff"></circle>
		</svg>
	)
}

export function NotifyIconMobile() {
	return (
		<svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mobile-bell" fill="none">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8 15.5C9.10457 15.5 10 14.6046 10 13.5H6C6 14.6046 6.89543 15.5 8 15.5Z"
				></path>
			</mask>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 15.5C9.10457 15.5 10 14.6046 10 13.5H6C6 14.6046 6.89543 15.5 8 15.5Z"
				fill="#6f6e84"
			></path>
			<path
				d="M10 13.5H11V12.5H10V13.5ZM6 13.5V12.5H5V13.5H6ZM9 13.5C9 14.0523 8.55228 14.5 8 14.5V16.5C9.65685 16.5 11 15.1569 11 13.5H9ZM10 12.5H6V14.5H10V12.5ZM8 14.5C7.44772 14.5 7 14.0523 7 13.5H5C5 15.1569 6.34315 16.5 8 16.5V14.5Z"
				fill="#6f6e84"
				mask="url(#mobile-bell)"
			></path>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.53082 2H7.46918C5.97623 2 4.71047 3.09779 4.49933 4.57573L4.02203 7.91687C3.97979 8.21252 3.87189 8.49495 3.70623 8.74343L1.86852 11.5H14.1315L12.2938 8.74343C12.1281 8.49495 12.0202 8.21252 11.978 7.91687L11.5007 4.57574C11.2895 3.09779 10.0238 2 8.53082 2ZM7.46918 1C5.47858 1 3.7909 2.46372 3.50939 4.43431L3.03208 7.77545C3.01096 7.92327 2.95701 8.06449 2.87418 8.18873L1.03647 10.9453C0.593431 11.6099 1.06982 12.5 1.86852 12.5H14.1315C14.9302 12.5 15.4066 11.6099 14.9635 10.9453L13.1258 8.18873C13.043 8.06449 12.989 7.92327 12.9679 7.77545L12.4906 4.43431C12.2091 2.46372 10.5214 1 8.53082 1H7.46918Z"
				fill="#6f6e84"
			></path>
		</svg>
	)
}

export function NotifyIconMobileAlert() {
	return (
		<svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mobile-bell" fill="white">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8 15.5C9.10457 15.5 10 14.6046 10 13.5H6C6 14.6046 6.89543 15.5 8 15.5Z"
				></path>
			</mask>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 15.5C9.10457 15.5 10 14.6046 10 13.5H6C6 14.6046 6.89543 15.5 8 15.5Z"
				fill="#6f6e84"
			></path>
			<path
				d="M10 13.5H11V12.5H10V13.5ZM6 13.5V12.5H5V13.5H6ZM9 13.5C9 14.0523 8.55228 14.5 8 14.5V16.5C9.65685 16.5 11 15.1569 11 13.5H9ZM10 12.5H6V14.5H10V12.5ZM8 14.5C7.44772 14.5 7 14.0523 7 13.5H5C5 15.1569 6.34315 16.5 8 16.5V14.5Z"
				fill="#6f6e84"
				mask="url(#mobile-bell)"
			></path>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.53526 1C8.53378 1 8.5323 1 8.53082 1H7.46918C5.47858 1 3.7909 2.46372 3.50939 4.43431L3.03208 7.77545C3.01096 7.92327 2.95701 8.06449 2.87418 8.18873L1.03647 10.9453C0.593431 11.6099 1.06982 12.5 1.86852 12.5H14.1315C14.9302 12.5 15.4066 11.6099 14.9635 10.9453L13.1258 8.18873C13.043 8.06449 12.989 7.92327 12.9679 7.77545L12.8444 6.91074C12.5722 6.96922 12.2898 7 12.0001 7C11.9487 7 11.8975 6.99903 11.8466 6.99711L11.978 7.91687C12.0202 8.21252 12.1281 8.49495 12.2938 8.74343L14.1315 11.5H1.86852L3.70623 8.74343C3.87189 8.49495 3.97979 8.21252 4.02203 7.91687L4.49933 4.57573C4.71047 3.09779 5.97623 2 7.46918 2H8.12615C8.21747 1.64523 8.35623 1.30951 8.53526 1ZM12.7025 5.91734L12.4906 4.43431C12.2631 2.84155 11.1169 1.57992 9.63463 1.15468C9.42379 1.42457 9.25814 1.73147 9.14904 2.064C10.3611 2.31853 11.3194 3.30708 11.5007 4.57574L11.702 5.98538C11.8001 5.99505 11.8995 6 12.0001 6C12.242 6 12.4772 5.97138 12.7025 5.91734Z"
				fill="#6f6e84"
			></path>
			<circle cx="12" cy="3" r="3" fill="#6966ff"></circle>
		</svg>
	)
}
