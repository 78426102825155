import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useNetwork } from 'wagmi'
import styled from 'styled-components'

import HelpIcon from 'assets/svg/app/help.svg'
import Button from 'components/Button'
import Connector from 'containers/Connector'
import NotifyIcon, { NotifyIconAlert } from 'components/Icons/NotifyIcon'
import FirstSeenWalletConnectionModal from 'sections/shared/modals/FirstSeenWalletConnectionModal'
import NavIcon from 'sections/shared/components/NavIcon'

import { useAutoConnect } from 'hooks/useAutoConnect'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setOpenModal, showNotification } from 'state/app/reducer'
import { selectNotification, selectShowModal } from 'state/app/selectors'
import { selectSessionActivated } from 'state/oneClickTrading/selectors'
import useLocalStorage from 'hooks/useLocalStorage'
import { get } from 'utils/localStore'

import Help from './Help'
import WalletActions from './WalletActions'
import BalanceActions from './BalanceActions'
import OneClickTrading from './OneClickTrading'
import Notifications from './Notifications'

const WalletButtons: React.FC = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const { isWalletConnected } = Connector.useContainer()
	const { chain: network } = useNetwork()
	const { openConnectModal } = useConnectModal()
	const openModal = useAppSelector(selectShowModal)
	const enableNotification = useAppSelector(selectNotification)
	const isSessionActivated = useAppSelector(selectSessionActivated)
	const [seenFlag, setSeenFlag] = useLocalStorage<boolean>('SEEN_FALG', false)
	const [firstVisitModal, setFirstVisitModal] = useState<boolean>(false)

	const openConnect = () => {
		if (seenFlag) {
			openConnectModal?.()
		} else {
			setFirstVisitModal(true)
		}
	}

	const closeFirstVisitModal = () => {
		setFirstVisitModal(false)
	}

	const visitModalConfirmed = () => {
		setSeenFlag(true)
		openConnectModal?.()
		setFirstVisitModal(false)
	}

	useAutoConnect()

	const handleHelp = () => dispatch(setOpenModal('help'))

	const unreadNotifications = get('unreadNotifications')
	const handleNotification = () => dispatch(showNotification(true))
	const NotificationIcon = React.useMemo(() => {
		return unreadNotifications ? <NotifyIconAlert /> : <NotifyIcon />
	}, [unreadNotifications])

	const walletIsNotConnected = (
		<IconContainer>
			<NavIcon icon={<HelpIcon />} onClick={handleHelp} />
			<LineStyle />
			<NavIcon icon={NotificationIcon} onClick={handleNotification} />
			<LineStyle />
			<ConnectButton
				size="xsmall"
				variant="flat"
				noOutline
				onClick={openConnect}
				data-testid="connect-wallet"
			>
				{t('common.wallet.connect-wallet')}
			</ConnectButton>
			{firstVisitModal && (
				<FirstSeenWalletConnectionModal
					onDismiss={closeFirstVisitModal}
					onConfirmed={visitModalConfirmed}
				></FirstSeenWalletConnectionModal>
			)}
		</IconContainer>
	)

	const walletIsConnectedButNotSupported = (
		<IconContainer>
			<NavIcon icon={<HelpIcon />} onClick={handleHelp} />
			<LineStyle />
			<NavIcon icon={NotificationIcon} onClick={handleNotification} />
			<LineStyle />
			<Button size="xsmall" variant="primary" data-testid="unsupported-network" mono>
				{t('common.wallet.unsupported-network')}
			</Button>
		</IconContainer>
	)

	const walletIsConnectedAndSupported = (
		<IconContainer>
			<BalanceActions />
			<LineStyle />
			<NavIcon icon={<HelpIcon />} onClick={handleHelp} />
			<LineStyle />
			<NavIcon icon={NotificationIcon} onClick={handleNotification} />
			<LineStyle />
			{isSessionActivated && (
				<>
					<OneClickTrading />
					<LineStyle />
				</>
			)}
			<WalletActions />
		</IconContainer>
	)

	return (
		<>
			<Container>
				{isWalletConnected
					? network?.unsupported
						? walletIsConnectedButNotSupported
						: walletIsConnectedAndSupported
					: walletIsNotConnected}
				{openModal === 'help' && <Help />}
			</Container>
			{enableNotification && <Notifications />}
		</>
	)
}

const Container = styled.div`
	display: flex;
	align-items: center;
`

const IconContainer = styled.div`
	display: flex;
	align-items: center;
`

const ConnectButton = styled(Button)`
	background: #6966ff;
	color: ${(props) => props.theme.colors.selectedTheme.button.dextoroPrimary.color};
	hover: {
		background: none;
	}
`

const LineStyle = styled.span`
	width: 1px;
	height: 24px;
	margin: 0 6px;
	background: ${(props) => props.theme.colors.selectedTheme.borderColor};
`

export default WalletButtons
