import { ZERO_WEI } from '@dextoroprotocol/sdk/constants'
import { formatDollars, truncateNumbers } from '@dextoroprotocol/sdk/utils'
import { useRouter } from 'next/router'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import LinkArrowIcon from 'assets/svg/app/link-arrow.svg'
import DextoroLogo from 'assets/svg/brand/DTORO.svg'
import Button from 'components/Button'
import { FlexDivRow } from 'components/layout/flex'
import { Body, LogoText } from 'components/Text'
import { StakingCard } from 'sections/dashboard/Stake/card'

import ROUTES from 'constants/routes'
import useClickOutside from 'hooks/useClickOutside'
import { selectDextoroPrice } from 'state/earn/selectors'
import { useAppSelector } from 'state/hooks'
import { selectDextoroRewards } from 'state/staking/selectors'
import media from 'styles/media'
import { selectCurrentTheme } from 'state/preferences/selectors'

const BalanceActions: FC = () => {
	const { t } = useTranslation()
	const router = useRouter()
	const dextoroPrice = useAppSelector(selectDextoroPrice)
	const dextoroRewards = useAppSelector(selectDextoroRewards)
	const [open, setOpen] = useState(false)
	const [rewardBalance, setRewardBalance] = useState(ZERO_WEI)
	const currentTheme = useAppSelector(selectCurrentTheme)

	const { ref } = useClickOutside(() => setOpen(false))

	const goToStaking = useCallback(() => {
		router.push(ROUTES.Staking.Home)
		setOpen(false)
	}, [router])

	const goToBuy = useCallback(() => {
		router.push(ROUTES.Home.Buy)
		setOpen(false)
	}, [router])

	const REWARDS = [
		{
			key: 'trading-rewards',
			title: t('dashboard.rewards.trading-rewards.balance-modal-title'),
			copy: t('dashboard.rewards.trading-rewards.copy'),
			button: t('dashboard.rewards.staking'),
			dextoroIcon: true,
			linkIcon: true,
			rewards: dextoroRewards,
			onClick: goToStaking,
			isDisabled: false,
		},
	]

	useEffect(
		() => setRewardBalance(dextoroPrice.mul(dextoroRewards)),
		[dextoroRewards, dextoroPrice]
	)

	return (
		<>
			<BalanceActionsButton
				size="xsmall"
				mono
				onClick={() => setOpen(!open)}
				currentTheme={currentTheme}
			>
				<DextoroLogo height={24} width={24} style={{ marginRight: '6px' }} />
				{formatDollars(rewardBalance, { maxDecimals: 2 })}
			</BalanceActionsButton>
			{open && (
				<RewardsTabContainer ref={ref}>
					<CardsContainer>
						{REWARDS.map((reward) => (
							<CardGrid key={reward.key}>
								<StyledBody size="medium" color="primary" weight="bold">
									{reward.title}
								</StyledBody>
								<StyledFlexDivRow>
									<div>
										<Body size="medium" color="secondary">
											{t('dashboard.rewards.claimable')}
										</Body>
										<LogoText
											dextoroIcon={reward.dextoroIcon}
											bold={false}
											size="medium"
											yellow={false}
										>
											{truncateNumbers(reward.rewards, 4)}
										</LogoText>
									</div>
									<BuyButton onClick={goToBuy} currentTheme={currentTheme}>
										Buy
									</BuyButton>
									<ClaimButton onClick={goToStaking} currentTheme={currentTheme}>
										{t('dashboard.rewards.claim')}
										{reward.linkIcon ? (
											<LinkArrowIcon height={8} width={8} style={{ marginLeft: '2px' }} />
										) : null}
									</ClaimButton>
								</StyledFlexDivRow>
							</CardGrid>
						))}
					</CardsContainer>
				</RewardsTabContainer>
			)}
		</>
	)
}

const RewardsTabContainer = styled.div`
	z-index: 100;
	position: absolute;
	right: 14%;
	top: 1px;

	${media.lessThan('mdUp')`
		padding: 15px;
	`}

	${media.greaterThan('mdUp')`
		margin-top: 56px;
	`}
`

const CardGrid = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	row-gap: 7px;
`

const CardsContainer = styled(StakingCard)`
	display: grid;
	width: 100%;
	height: 100px;
	grid-template-rows: repeat(3, 1fr);
	grid-gap: 20px;
	background: ${(props) => props.theme.colors.selectedTheme.background};
	border: ${(props) => props.theme.colors.selectedTheme.border};
	padding: 15px !important;
`

const StyledFlexDivRow = styled(FlexDivRow)`
	column-gap: 0px;
	align-items: center;
`
const BalanceActionsButton = styled(Button)<{ currentTheme: string }>`
	font-size: 14px;
	min-width: unset;
	background: none;
	border-width: length;
	font-feature-settings: 'zero' 0;
	font-family: ${(props) => props.theme.fonts.regular};
	border: ${(props) => props.theme.colors.selectedTheme.border};
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primaryWhite};
	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.1)' : 'brightness(1)')};
	}
`

const StyledBody = styled(Body)`
	font-size: 14px;
`
const BuyButton = styled.div<{ currentTheme: string }>`
	cursor: pointer;
	display: flex;
	margin: auto;
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	color: ${(props) => props.theme.colors.selectedTheme.button.text.lightWhite};
	width: 100%;
	height: 28px;
	font-size: 13px;
	border: none;
	border-radius: 10px;
	padding: 0px 10px;
	justify-content: center;
	align-items: center;
	margin-left: 50px;
	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.1)' : 'brightness(0.9)')};
	}
`

const ClaimButton = styled.div<{ currentTheme: string }>`
	cursor: pointer;
	justify-content: center;
	align-items: center;
	color: ${(props) => props.theme.colors.common.dark.yellow};
	display: flex;
	margin: auto;
	background: #5973fe;
	width: 80px;
	height: 28px;
	font-size: 12px;
	border: none;
	border-radius: 10px;
	padding: 0px 10px;
	margin-left: 15px;
	&:hover {
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.1)' : 'brightness(0.9)')};
	}
`

export default BalanceActions
